import fetch from 'auth/FetchInterceptor'

const RoleBuilderService = {}

RoleBuilderService.createRoleBuilderData = function (data) {
  return fetch({
    url: '/jobRole/',
    method: 'post',
    data
  })
}

RoleBuilderService.updateRoleBuilderData = function (data) {
  return fetch({
    url: `/jobRole/${data.jobRoleId}`,
    method: 'put',
    data
  })
}


RoleBuilderService.getAllJobRoles = function (params) {
  return fetch({
    url: '/jobRole/',
    method: 'get',
    params
  })
}
// updated 31-05 ved for job role  clone integration

RoleBuilderService.cloneRoleBuilderData = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/clone`,
    method: 'get',
    params
  })
}

RoleBuilderService.fetchRoleBuilderDataByParams = function (params) {
  return fetch({
    url: '/jobRole/',
    method: 'get',
    params
  })
}


RoleBuilderService.getJobRoleById = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}`,
    method: 'get'
  })
}


RoleBuilderService.downloadRolePDF = function (params) {
  let url = `/jobRole/${params.jobRoleId}/report/pdf`;
  return fetch({
    url: url,
    method: 'get',
    responseType: 'blob'
  })
}


RoleBuilderService.deleteRoleBuilder = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}`,
    method: 'delete',
  })
}
RoleBuilderService.getRoleBuilderFile = function (data) {
  return fetch({
    url: `/jobRole/${data.jobRoleId}/document/`,
    method: 'get',
    data
  })
}

RoleBuilderService.createRoleBuilderFile = function (data,params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/document/`,
    method: 'post',
    data
  })
}

RoleBuilderService.getRoleBuilderFiles = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/document`,
    method: 'get'
  })
}

RoleBuilderService.deleteRoleBuilderFile = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/document/${params.jobRoleDocumentId}`,
    method: 'delete',
  })
}
RoleBuilderService.updateRoleBuilderFile = function (params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/document/${params.jobRoleDocumentId}`,
    method: 'put',
  })
}

RoleBuilderService.addCodesForConsideration = function (data, params) {
  return fetch({
    url: `/jobRole/${params.jobRoleId}/skills`,
    method: 'post',
    data
  })
}


export default RoleBuilderService