import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
// import { env } from './EnvironmentConfig'
export const APP_NAME = 'Assess Management Ltd (validateskills.com)';

console.log("process.env.env ", process.env);
console.log("process.env.REACT_APP_BUILD_ENV ", process.env.REACT_APP_BUILD_ENV);
export const API_BASE_URL = process.env.REACT_APP_BASE_URL

//process.env.NODE_ENV === "production" ? "https://itsa-prod.validateskills.com/api/v1" : "https://itsa-dev.validateskills.com/api/v1";

console.log("process.env.API_BASE_URL ", API_BASE_URL);
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/org/my-dashboard`;
export const UNAUTHENTICATED_ENTRY = '/login';
// console.log("API_BASE_URL", API_BASE_URL);
export const THEME_CONFIG = {
	navCollapsed: true,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '#41B149',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
